

















































import { Component, Vue, Watch } from 'vue-property-decorator';
import format from '@/filter/dateFormat';
import ErrorAlert from '@/components/ErrorAlert.vue';

@Component({
  components: { ErrorAlert }
})
export default class YearViewDialog extends Vue {
  dialog: boolean = false;
  loading: boolean = false;
  error: any = null;

  year: string = format(Date.now(), 'yyyy');
  file: string | null = null;
  format: string | null = null;

  get download(): string {
    return `Jahresuebersicht-${this.year}.${this.format}`;
  }

  get years(): string[] {
    const currentYear = new Date().getFullYear();
    let startYear = currentYear - 10;
    const years: string[] = [];

    while (startYear <= currentYear) {
      years.push((startYear++).toString());
    }

    return years.reverse();
  }

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) {
      this.year = format(Date.now(), 'yyyy');

      return;
    }

    setTimeout(() => {
      this.error = null;
      this.file = null;
      this.format = null;
      this.loading = false;
      // @ts-ignore
      this.$refs.form.reset();
    }, 200);
  }

  async save(format: 'pdf' | 'csv') {
    this.error = null;

    // @ts-ignore
    const result = await this.$refs.form.validate();

    this.loading = true;

    try {
      const response = await this.$http.get(`api/conservatorship/year-${format}`, {
        responseType: 'blob',
        params: { year: this.year }
      });

      this.file = URL.createObjectURL(response.data);
      this.format = format;
    } catch (error) {
      this.error = error;
    }

    this.loading = false;
  }
}
